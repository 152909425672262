<template>
  <a-modal
    :visible="visible"
    title="新增群组"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <a-form
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      layout="vertical"
    >
      <a-form-item label="群组名称" v-bind="validateInfos.groupName">
        <div class="pulseClass">
          <div class="pulseImage">
            <img
              src="@/assets/images/box/pulseIcon.png"
              alt=""
              class="pulse-img"
            />
          </div>
          <a-input
            v-model:value="form.groupName"
            placeholder="请输入群组名称"
            class="issInput"
          />
        </div>
      </a-form-item>
      <a-form-item label="描述">
        <a-input
          v-model:value="form.description"
          placeholder="请输入该群组的描述"
        />
      </a-form-item>

      <a-form-item label="父级">
        <a-select v-model:value="form.parentId" placeholder="请选择">
          <a-select-option
            v-for="item in parentList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
// watch
import { reactive, toRaw, toRefs } from 'vue';
import { Form, Select } from 'ant-design-vue';

// import { validMobile, validEmail } from '@/utils/validation';
// import {
//   PlusOutlined,
// } from '@ant-design/icons-vue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    // PlusOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      groupName: '',
      description: '',
      parentId: null,
      userId: '',
      sortValue: '',
    });

    const state = reactive({
      parentList: [],
    });

    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      groupName: [required],
    });

    const handleCancel = () => {
      context.emit('update:visible', false);
      // 表单重置
      resetFields();
    };

    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel,
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
          resetFields();
        });
      },
      addMessage: () => {},
    };
  },
};
</script>
<style lang="less" scoped>
.live-group {
  padding: 0 12px 15px 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}
.live-group .group-input[data-v-61219ec2] {
  flex: 1;
  padding-bottom: 15px;
}
.flex {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
span.anticon.anticon-minus-circle {
  color: #5979f8 !important;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #313d5f;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.addMessage {
  margin: 0px 76px 24px;
  color: #ff7b00;
  cursor: pointer;
  .issIcon {
  }
}
.pulseClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pulseImage {
    border-radius: 8px 0px 0px 8px;
    opacity: 1;
    //background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #e5e6eb;
    .pulse-img {
      margin: 8px 27px;
    }
  }
}
// border-radius: 0px 4px 4px 0px;
.ant-input {
  border-radius: 8px;
}
.issInput {
  border-radius: 0px 8px 8px 0px;
  border-left: none;
}
</style>
